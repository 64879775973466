import { MsalProvider } from "@azure/msal-react";
import { LoadingIndicator } from "../../processing-pcp";
import { useInitAuth, useInitPCP, useInitInterceptor } from "../../../hooks";

export const PCP = () => {
  // --------------------- PCP MECHANISM BEGINS WITH THIS HOOK ----------------------------
  const { isPCDProcessing, processedPCD, pcd } = useInitPCP();
  const { msalInstance } = useInitAuth(pcd, processedPCD !== undefined);
  useInitInterceptor(pcd, msalInstance);

  if (isPCDProcessing || !processedPCD || !msalInstance) {
    return <LoadingIndicator loadingDesc="Processing Application Data..." />;
  }

  return (
    <MsalProvider instance={msalInstance}>
      {processedPCD.inflatedClient}
    </MsalProvider>
  );
};
