import {
  ICommandContext,
  TPrepareCommand,
  TCommand,
  TGenericData,
} from "../../interfaces";
import { isRedirectCommand } from "./guards";
import { getCommand } from "./getCommand";
import { createDynamicData } from "../createDynamicData";
import { getExtendedCommandContext } from "./getExtendedCommandContext";

export const redirect: TPrepareCommand =
  (commandContext: ICommandContext, command: TCommand) =>
  (eventDetails: TGenericData) => {
    if (!isRedirectCommand(command)) {
      console.warn(
        "Please check the redirect properties. Something seems to be wrong.",
      );

      return;
    }

    const extendedCommandContext = getExtendedCommandContext(commandContext);
    const { params, url, commandCallback } = command.commandProps;

    const callbackCommandFunction = commandCallback
      ? getCommand(commandCallback, commandContext)
      : undefined;

    if (!params) {
      commandContext.navigate(url);
      callbackCommandFunction?.(eventDetails);

      return;
    }

    let dynamicallyGeneratedUrl = url;

    dynamicallyGeneratedUrl = createDynamicData(
      dynamicallyGeneratedUrl,
      extendedCommandContext,
      params,
      eventDetails,
    );

    commandContext.navigate(dynamicallyGeneratedUrl);
    callbackCommandFunction?.(eventDetails);
  };
