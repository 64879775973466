import {
  COMMAND_TYPES,
  ICreateEntityCommand,
  ILogoutCommand,
  IRedirectCommand,
  IDownloadCommand,
  ISetDataCommand,
  IUpdateEntityCommand,
  IAPICallCommand,
  IShowSnackBarCommand,
} from "../../interfaces";

// todo: clarify approach one more time and exchange guards with final solution if needed
export const isRedirectCommand = (data: unknown): data is IRedirectCommand => {
  const toValidate = data as IRedirectCommand;

  return (
    toValidate.type === COMMAND_TYPES.redirect &&
    toValidate.commandProps !== undefined &&
    toValidate.commandProps.url !== undefined
  );
};

export const isDownloadCommand = (data: unknown): data is IDownloadCommand => {
  const toValidate = data as IDownloadCommand;

  return (
    toValidate.type === COMMAND_TYPES.download &&
    toValidate.commandProps !== undefined &&
    toValidate.commandProps.url !== undefined
  );
};

export const isLogoutCommand = (data: unknown): data is ILogoutCommand => {
  const toValidate = data as ILogoutCommand;

  return toValidate.type === COMMAND_TYPES.logout;
};

export const isSetDataCommand = (data: unknown): data is ISetDataCommand => {
  const toValidate = data as ISetDataCommand;

  return (
    toValidate.type === COMMAND_TYPES.setData &&
    toValidate.commandProps !== undefined &&
    toValidate.commandProps.storageKey !== undefined &&
    toValidate.commandProps.storageData !== undefined
  );
};

export const isShowSnackBarCommand = (
  data: unknown,
): data is IShowSnackBarCommand => {
  const toValidate = data as IShowSnackBarCommand;

  return (
    toValidate.type === COMMAND_TYPES.showSnackBar &&
    toValidate.commandProps !== undefined &&
    toValidate.commandProps.description !== undefined
  );
};

export const isAPICallCommand = (data: unknown): data is IAPICallCommand => {
  const toValidate = data as IAPICallCommand;

  return (
    toValidate.type === COMMAND_TYPES.apiCall &&
    toValidate.commandProps !== undefined &&
    toValidate.commandProps.configuration !== undefined
  );
};

export const isUpdateEntityCommand = (
  data: unknown,
): data is IUpdateEntityCommand => {
  const toValidate = data as IUpdateEntityCommand;

  return (
    toValidate.type === COMMAND_TYPES.updateEntity &&
    toValidate.commandProps !== undefined &&
    toValidate.commandProps.id !== undefined &&
    toValidate.commandProps.type !== undefined
  );
};

export const isCreateEntityCommand = (
  data: unknown,
): data is ICreateEntityCommand => {
  const toValidate = data as ICreateEntityCommand;

  return (
    toValidate.type === COMMAND_TYPES.createEntity &&
    toValidate.commandProps !== undefined &&
    toValidate.commandProps.type !== undefined
  );
};
