import { useEffect, useMemo, useRef } from "react";
import { useCommandContext } from "../../../hooks/useCommandContext";
import { TCommand } from "../../../interfaces";
import { getCommand } from "../../../helpers/commands";

interface IExecuteCommand {
  readonly command: TCommand;
  readonly commandConfig: { enabled: boolean };
}

// todo: add documentation for this component
export const ExecuteCommandComponent = ({
  command,
  commandConfig,
}: IExecuteCommand) => {
  const isGettingExecuted = useRef(false);
  const commandContext = useCommandContext();

  const toExecute = useMemo(
    () => getCommand(command, commandContext),
    [command, commandContext],
  );

  useEffect(() => {
    // execute when condition is met
    const checkCondition = () => {
      if (
        isGettingExecuted.current ||
        (commandConfig && !commandConfig.enabled)
      ) {
        return;
      }

      isGettingExecuted.current = true;
      toExecute({});
    };

    checkCondition();
  }, [commandConfig?.enabled]);

  return null;
};
