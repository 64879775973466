import {
  ICommandContext,
  TPrepareCommand,
  TCommand,
  TGenericData,
} from "../../interfaces";
import { isDownloadCommand } from "./guards";
import { getCommand } from "./getCommand";
import { createDynamicData } from "../createDynamicData";
import { getExtendedCommandContext } from "./getExtendedCommandContext";

export const download: TPrepareCommand =
  (commandContext: ICommandContext, command: TCommand) =>
  (eventDetails: TGenericData) => {
    if (!isDownloadCommand(command)) {
      console.warn(
        "Please check the download properties. Something seems to be wrong.",
      );

      return;
    }

    const initiateDownload = (url: string) => {
      const link = document.createElement("a");
      link.href = url;
      link.download = "";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    const extendedCommandContext = getExtendedCommandContext(commandContext);
    const { url, commandCallback, params } = command.commandProps;

    const callbackCommandFunction = commandCallback
      ? getCommand(commandCallback, commandContext)
      : undefined;

    if (!params) {
      initiateDownload(url);
      callbackCommandFunction?.(eventDetails);
      return;
    }

    let dynamicallyGeneratedUrl = url;

    dynamicallyGeneratedUrl = createDynamicData(
      dynamicallyGeneratedUrl,
      extendedCommandContext,
      params,
      eventDetails,
    );

    initiateDownload(dynamicallyGeneratedUrl);

    callbackCommandFunction?.(eventDetails);
  };
