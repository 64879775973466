import { getDataByPath } from "@paul/paul-components-collector-package";
import {
  IContextData,
  IParams,
  ISource,
  SOURCES,
  TGenericData,
} from "../interfaces";
import { replaceValueInObject } from "./replaceValueInObject";
import { createTransformedData } from "./transformations/createTransformedData";

/**
 * @returns {Object} - (Dynamic) data with replaced placeholders like 'id: ":personId"' -> 'id: "1ccf-34-xyz"'. If no value
 * under the path for the placeholder was found and no defaultValue was defined, the placeholder gets exchanged with
 * undefined instead.
 * @param {Object} inputData - Initial data with placeholders in the object values. Type gets returned as it got put in.
 * @param {ICommandContext} contextData - Takes the command context for resolving dynamic values e.g. from redux or react-router
 * @param {Object} params - Optional definition where to take the data from to replace placeholders in inputData
 * @param {Object} eventDetails - Optional data of an event, like clicking on a table row.
 * @see ILoadData for more information how this can look like
 * */
export const createDynamicData = <T extends string | Record<string, unknown>>(
  inputData: T,
  contextData: IContextData,
  params: IParams = {},
  eventDetails: TGenericData = {},
): T => {
  const { apiStorage, componentContext, urlParams, queryParams, userContext } =
    contextData;
  const eventContext = {
    userContext,
    componentContext,
    urlParams,
    queryParams,
    apiStorage,
    eventDetails,
  };
  let dynamicData = inputData;

  if (!params) {
    return dynamicData;
  }

  const createData = (fromSource: ISource, sourceType: SOURCES) => {
    Object.entries(fromSource).forEach(([paramKey, param]) => {
      const { path, transform, defaultValue } = param;
      let replacementValue;

      switch (sourceType) {
        case SOURCES.url:
          replacementValue = createTransformedData(
            urlParams[path],
            eventContext,
            transform,
          );
          break;

        case SOURCES.queryParams:
          replacementValue = createTransformedData(
            queryParams?.get(path),
            eventContext,
            transform,
          );
          break;

        case SOURCES.storage:
          replacementValue = createTransformedData(
            getDataByPath(path, {
              ...apiStorage,
              ...componentContext,
              ...userContext,
            }),
            eventContext,
            transform,
          );
          break;

        case SOURCES.event:
          replacementValue = createTransformedData(
            // If we need the whole object/array, instead of a specific path, path must be an empty string
            // todo: clarify this approach, maybe discuss and document
            path !== ""
              ? getDataByPath(path, eventDetails ?? {})
              : eventDetails,
            eventContext,
            transform,
          );
          break;

        default:
          console.warn(
            `No function implemented for source type ${sourceType}. Please check your PCD again.`,
          );
      }

      replacementValue =
        replacementValue === undefined || replacementValue === null
          ? defaultValue
          : replacementValue;

      if (replacementValue === undefined) {
        console.warn(
          `No data and no defaultValue found for ${paramKey} and path ${path}. Please check your PCD again.`,
        );
      }

      // Here we want to manipulate a string with a string. Like in the following:
      // commandProps: {
      //   url: "/reassign_device_or_node/device/:deviceId",
      //       params: {
      //         eventSource: {
      //          ":deviceId": {
      //             path: "value",
      //           },
      //       },
      //   },
      // },
      if (
        typeof dynamicData === "string" &&
        typeof replacementValue === "string"
      ) {
        dynamicData = dynamicData.replace(
          paramKey,
          String(replacementValue),
        ) as T;
      }

      // Here we want to return the plain data without nesting it in an object like in the following:
      // commandProps: {
      //   storageKey: "selectedSiteIds.data",
      //   storageData: ":selectedSiteIds",
      //   params: {
      //      eventSource: { ":siteIds": { path: "" } },
      //    },
      //  }
      else if (
        typeof dynamicData === "string" &&
        (typeof replacementValue === "object" || replacementValue === undefined)
      ) {
        dynamicData = replacementValue as T;
      }

      // Here we want to replace a whole placeholder nested inside an object like in the following:
      // commandProps: {
      //   storageKey: "selectedSiteIds",
      //   storageData: {
      //     data: ":selectedSiteIds",
      //     relatedPDID: "PDID: :pdid",
      //   },
      //   params: {
      //     eventSource: {
      //       ":selectedSiteIds": {
      //         path: "data.relationships.DeviceToSite",
      //         transform: {
      //           type: TRANSFORM_TYPE.reduceArrayByKey,
      //               mappingPath: "relTo.id",
      //         },
      //       },
      //       ":pdid": {
      //         path: "data.attributes.thingPDIDDevice.values.0.value",
      //       },
      //     },
      //   },
      else {
        dynamicData = replaceValueInObject(
          dynamicData as Record<string, unknown>,
          paramKey,
          replacementValue,
        ) as T;
      }
    });
  };

  const { urlSource, queryParamsSource, eventSource, dataSource } = params;

  if (urlSource) {
    createData(urlSource, SOURCES.url);
  }

  if (queryParamsSource) {
    createData(queryParamsSource, SOURCES.queryParams);
  }

  if (eventSource) {
    createData(eventSource, SOURCES.event);
  }

  if (dataSource) {
    createData(dataSource, SOURCES.storage);
  }

  return dynamicData as T;
};
