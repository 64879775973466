import { CssBaseline, ThemeProvider } from "@mui/material";
import { LicenseInfo } from "@mui/x-license";
import { getThemeData, Theme } from "paul-theme-package";
import { Provider as ReduxProvider } from "react-redux";
import { Suspense } from "react";
import { PCP } from "./features/pcp";
import { store } from "./store/store";
import { LoadingIndicator } from "./features/processing-pcp";
import { LocalizedProvider } from "./localization";
import "./configs/i18n";

/* Use following line in DEV mode if you want to improve the debug experience in the console. This ignores all the
 * "warnings" and therefore provides a better overview in the browsers console in DEV mode:
 */
// console.warn = () => {};

export const App = () => {
  LicenseInfo.setLicenseKey(
    "f8370a1770dde0be2e56bad6f23f4a00Tz04MzA3MyxFPTE3Mzc5Nzk2MjkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
  );

  return (
    <ThemeProvider theme={getThemeData(Theme.LIGHT_MODERN)}>
      <LocalizedProvider>
        <CssBaseline />

        <ReduxProvider store={store}>
          {/* Suspense necessary for translations */}
          <Suspense
            fallback={<LoadingIndicator loadingDesc="Loading Data..." />}
          >
            <PCP />
          </Suspense>
        </ReduxProvider>
      </LocalizedProvider>
    </ThemeProvider>
  );
};
