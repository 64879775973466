import { redirect } from "./redirect";
import { logout } from "./logout";
import { setData } from "./setData";
import { updateEntity } from "./updateEntity";
import { createEntity } from "./createEntity";
import { download } from "./download";
import { apiCall } from "./apiCall";
import { showSnackBar } from "./showSnackBar";

export const COMMANDS = {
  redirect,
  setData,
  logout,
  updateEntity,
  createEntity,
  download,
  apiCall,
  showSnackBar,
};
