import { getDataByPath } from "@paul/paul-components-collector-package";
import { IReduceArrayByKey } from "../../interfaces";

export const reduceArrayByKey = (
  toTransform: Record<string, unknown>[],
  transformationWay: IReduceArrayByKey,
) =>
  toTransform.reduce((accumulator: unknown[], item) => {
    return [...accumulator, getDataByPath(transformationWay.mappingPath, item)];
  }, []);
