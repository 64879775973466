import {
  ICommandContext,
  TCommand,
  TGenericData,
  TPrepareCommand,
} from "../../interfaces";
import { isSetDataCommand } from "./guards";
import { createDynamicData } from "../createDynamicData";
import { pcpActions } from "../../features/pcp/store/PCPSlice";
import { setDataWithCallback } from "../../features/pcp/store/PCPSagaActions";
import { getExtendedCommandContext } from "./getExtendedCommandContext";
import { COMMANDS } from "./Commands";

export const setData: TPrepareCommand =
  (commandContext: ICommandContext, command: TCommand) =>
  (eventDetails: TGenericData) => {
    if (!isSetDataCommand(command)) {
      console.warn(
        "Please check the setData properties. Something seems to be wrong.",
      );

      return;
    }

    const extendedCommandContext = getExtendedCommandContext(commandContext);
    const { storageKey, storageData, params, commandCallback } =
      command.commandProps;

    const data = createDynamicData(
      storageData,
      extendedCommandContext,
      params,
      eventDetails,
    );

    if (!commandCallback) {
      commandContext.dispatch(
        pcpActions.setComponentContext({
          storageKey,
          data,
        }),
      );

      return;
    }

    commandContext.dispatch(
      setDataWithCallback({
        storageKey,
        storageData: data,
        eventDetails,
        commandCallback: commandCallback
          ? COMMANDS[commandCallback.type](commandContext, commandCallback)
          : undefined,
      }),
    );
  };
