import { ActionCreator } from "@reduxjs/toolkit";
import {
  ICreateEntityProps,
  IRedirectProps,
  ISetDataProps,
  IUpdateEntityProps,
  IDownloadProps,
  IShowSnackBarProps,
} from "./ICommandProps";
import { ICommandContext } from "./ICommandContext";
import { TGenericData } from "./IPCD";
import { IDynamicApiCall } from "./IDynamicApiCall";

export enum COMMAND_TYPES {
  redirect = "redirect",
  logout = "logout",
  setData = "setData",
  showSnackBar = "showSnackBar",
  updateEntity = "updateEntity",
  apiCall = "apiCall",
  createEntity = "createEntity",
  download = "download",
}

export interface ICommand {
  type: COMMAND_TYPES;
  commandProps?: unknown;
}

export interface IShowSnackBarCommand extends ICommand {
  type: COMMAND_TYPES.showSnackBar;
  commandProps: IShowSnackBarProps;
}

export interface IRedirectCommand extends ICommand {
  type: COMMAND_TYPES.redirect;
  commandProps: IRedirectProps;
}

export interface IDownloadCommand extends ICommand {
  type: COMMAND_TYPES.download;
  commandProps: IDownloadProps;
}

export interface ILogoutCommand extends ICommand {
  type: COMMAND_TYPES.logout;
}

export interface ISetDataCommand extends ICommand {
  type: COMMAND_TYPES.setData;
  commandProps: ISetDataProps;
}

export interface IUpdateEntityCommand extends ICommand {
  type: COMMAND_TYPES.updateEntity;
  commandProps: IUpdateEntityProps;
}

export interface IAPICallCommand extends ICommand {
  type: COMMAND_TYPES.apiCall;
  commandProps: IDynamicApiCall;
}

export interface ICreateEntityCommand extends ICommand {
  type: COMMAND_TYPES.createEntity;
  commandProps: ICreateEntityProps;
}

// TCommand must get extended with every new command
export type TCommand =
  | IShowSnackBarCommand
  | IRedirectCommand
  | IDownloadCommand
  | ILogoutCommand
  | ISetDataCommand
  | IUpdateEntityCommand
  | ICreateEntityCommand
  | IAPICallCommand;
export type TPrepareCommand = (
  commandContext: ICommandContext,
  command: TCommand,
) => TExecuteCommand;
export type TExecuteCommand = (eventDetails: TGenericData) => unknown;
export type TCommandSet = Record<string, TExecuteCommand>;

export interface IActionProps {
  [key: string]: IActionProps | IActionProps[] | ICommand;
}

export interface IAvailableActions {
  [key: string]: ActionCreator<unknown>;
}
