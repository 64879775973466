import {
  ICommandContext,
  TCommand,
  TGenericData,
  TPrepareCommand,
} from "../../interfaces";
import { isShowSnackBarCommand } from "./guards";
import { createDynamicData } from "../createDynamicData";
import { pcpActions } from "../../features/pcp/store/PCPSlice";
import { setDataWithCallback } from "../../features/pcp/store/PCPSagaActions";
import { getExtendedCommandContext } from "./getExtendedCommandContext";
import { COMMANDS } from "./Commands";
import { initialSnackBarProps } from "../../constants";

const SNACKBAR_STORAGE_KEY = "snackBar";
const DURATION_BUFFER = 500;

export const showSnackBar: TPrepareCommand =
  (commandContext: ICommandContext, command: TCommand) =>
  (eventDetails: TGenericData) => {
    if (!isShowSnackBarCommand(command)) {
      console.warn(
        "Please check the showSnackBar properties. Something seems to be wrong.",
      );

      return;
    }

    const extendedCommandContext = getExtendedCommandContext(commandContext);
    const { params, commandCallback, ...snackBarProps } = command.commandProps;

    const data = createDynamicData(
      snackBarProps,
      extendedCommandContext,
      params,
      eventDetails,
    );

    const { anchorOrigin, urgency, ...alertProps } = data;

    const finalSnackBarProps = {
      open: true,
      anchorOrigin,
      urgency,
      alertProps,
      onCloseCallback: () =>
        setTimeout(
          () =>
            commandContext.dispatch(
              pcpActions.setComponentContext({
                storageKey: SNACKBAR_STORAGE_KEY,
                data: initialSnackBarProps,
              }),
            ),
          DURATION_BUFFER,
        ),
    };

    if (!commandCallback) {
      commandContext.dispatch(
        pcpActions.setComponentContext({
          storageKey: SNACKBAR_STORAGE_KEY,
          data: finalSnackBarProps,
        }),
      );

      return;
    }

    commandContext.dispatch(
      setDataWithCallback({
        storageKey: SNACKBAR_STORAGE_KEY,
        storageData: finalSnackBarProps,
        eventDetails,
        commandCallback: commandCallback
          ? COMMANDS[commandCallback.type](commandContext, commandCallback)
          : undefined,
      }),
    );
  };
